import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { graphql, Link } from "gatsby";

// Local imports
import Layout from "src/layouts/LayoutWhiteHeader";
import SEO from "src/components/generic/SEO/SEO";
import config from "data/SiteConfig";

// Styles
// import styles from "./index.module.scss";

// Icons
import {
	FaLayerGroup,
	FaProjectDiagram,
	FaChartLine,
	FaClock
} from "react-icons/fa";
import StripContentList from "src/components/generic/StripContentList/StripContentList";
import { getCookieValue } from "src/utils/utils";

const templateResources = [
	{
		h1title: "GA4 SEO Template",
		description: (
			<React.Fragment>
				<p>
					A Looker Studio template to help you find out where our
					organic traffic coming from and how it&apos;s changed.
				</p>
			</React.Fragment>
		),
		linkArray: [
			{
				url:
					"https://lookerstudio.google.com/u/0/reporting/a69670bf-af7b-4c2e-a593-e95103a9536f/",
				anchor: "Download template"
			},
			{
				url: "/resources/how-to-setup-ga4-seo-dashboard/",
				anchor: "How to use the report"
			}
		],
		id: "ga4seo_basic_report"
	},
	{
		h1title: "Blending GA4 and Search Console Helper",
		description: (
			<React.Fragment>
				<p>
					This is a Looker Studio template to help you blend together
					GA4 and GSC. It goes along with this blog post which will
					explain how to use it.
				</p>
			</React.Fragment>
		),
		linkArray: [
			{
				url:
					"https://lookerstudio.google.com/u/0/reporting/6b43c15a-c144-4eac-bd29-edf23b9ff690/",
				anchor: "Download template"
			},
			{
				url: "/resources/join-ga4-gsc-together/",
				anchor: "How to use the report"
			}
		],
		id: "join_ga4_gsc_report"
	},
	{
		h1title: "Search Console Template",
		description: (
			<React.Fragment>
				<p>
					An all purpose Search Console template to help you get more
					from your Search Console. You can&apos;t just make a copy of
					this directly, you&apos;ll need to follow the set-up
					instructions in the blog post.
				</p>
			</React.Fragment>
		),
		linkArray: [
			{
				url:
					"https://lookerstudio.google.com/u/0/reporting/77da2235-b5be-4b0b-8474-4d3a9914edae/",
				anchor: "Download template"
			},
			{
				url: "/resources/how-to-setup-gsc-dashboard/",
				anchor: "How to use the report"
			}
		],
		id: "searchconsole_basic_report"
	}
];

const propTypes = {
	data: PropTypes.shape().isRequired
};

const Index = ({ data }) => {
	useEffect(() => {
		const cookieValue = getCookieValue("resource_library");
		if (!cookieValue || cookieValue !== "true") {
			window.location.href = "/resource-library/";
		}
	}, []);

	const [highlightedResources, setHighlightedResources] = useState([]);
	const [remainingResources, setRemainingResources] = useState(
		templateResources
	);
	const [thankYouMessage, setThankYouMessage] = useState("");

	useEffect(() => {
		const highlightId = localStorage.getItem("resourceHighlight");
		if (highlightId) {
			const filteredResources = templateResources
				.filter(resource => resource.id === highlightId)
				.map(resource => ({ ...resource, highlighted: true }));
			const newRemainingResources = templateResources.filter(
				resource => resource.id !== highlightId
			);
			setHighlightedResources(filteredResources);
			setRemainingResources(newRemainingResources);
		}
	}, []);

	useEffect(() => {
		const newsletterThankYou = localStorage.getItem("newsletterThankYou");
		if (newsletterThankYou) {
			setThankYouMessage(
				<span>
					<p>Thanks for subscribing to our newsletter!</p>
					<p>
						As an immediate thanks, here&apos;s access to our
						resource library.
					</p>
					<p>
						In here we&apos;ve got all our free Looker Studio
						templates and all of our slightly more complex data work
						which is slowly being added!
					</p>
				</span>
			);
		}
	}, []);

	useEffect(() => {
		return () => {
			localStorage.removeItem("newsletterThankYou");
		};
	}, []);

	const title = "Working with agencies";
	const description =
		"You've got strategies to make, actions to change on your clients site, all while juggling communications and getting ready for the quarterly review. You don't have time to spend debugging a data issue for 4 hours.";

	return (
		<Layout>
			<Helmet title={config.siteTitle} />
			<SEO pageTitle={title} pageDescription={description} />
			<section>
				<div className={`m-b-xl m-t-xl container outerContainer`}>
					<h1 className="is-size-3 m-b-lg">Free Templates</h1>
					{thankYouMessage && (
						<span className="notificationSuccess notification">
							<p>{thankYouMessage}</p>
						</span>
					)}
					{highlightedResources.length > 0 && (
						<StripContentList
							postList={highlightedResources}
							classes="m-t-lg"
							noBotMargin={true}
							rowType="resource"
						/>
					)}
					<StripContentList
						postList={remainingResources}
						classes="m-t-lg"
						noBotMargin={true}
						rowType="resource"
					/>
				</div>
			</section>
			<div style={{ minHeight: "400px" }}></div>
		</Layout>
	);
};

Index.propTypes = propTypes;

export default Index;

// The graphql query made here will automatically be entered as the
// prop data in data
export const pageQuery = graphql`
	query TemplateResourceLibraryQuery {
		dataDiagram: file(
			relativePath: { eq: "images/pages/data-diagram.jpg" }
		) {
			childImageSharp {
				fluid(maxWidth: 933, maxHeight: 658) {
					...GatsbyImageSharpFluid
				}
			}
		}
	}
`;
